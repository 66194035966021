import {
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  exportProject,
  getProject,
  ProjectData,
  updateProject,
} from '../client';
import { AppContext } from '../context';
import { CanceledError } from 'axios';
import LoadingPlaceholder from './LoadingPlaceholder';
import { DownloadOutlined } from '@ant-design/icons';

const requiredRules = [{ required: true, message: 'Required parameter!' }];

export default function ProjectSettings() {
  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [changed, setChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { projectId, projectName, setProjectName, user } =
    useContext(AppContext);
  const [updateGptLimit, setUpdateGptLimit] = useState(false);
  const exportLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (projectId === null) return;
    setFetching(true);
    const abortController = new AbortController();
    (async () => {
      try {
        const projData = await getProject(projectId, abortController);
        setProjectData(projData);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching project');
        console.log('Error fetching project:', error);
      }
      setFetching(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [projectId, messageApi]);

  useEffect(() => {
    if (projectId === null || !projectData || !saving) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await updateProject(projectId, projectData, abortController);
        setProjectName(projectData.name);
        messageApi.success('Saved');
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error updating project');
        console.log('Error updating project:', error);
      }
      setSaving(false);
      setChanged(true);
    })();
    return () => {
      abortController.abort();
    };
  }, [projectId, messageApi, projectData, saving, setProjectName]);

  if (!projectData) {
    return (
      <>
        {contextHolder}
        <LoadingPlaceholder />
      </>
    );
  }

  const onExport = async () => {
    if (projectId === null) return;
    const exportData = await exportProject(projectId);
    if (!exportLinkRef.current) return;
    const exportText = JSON.stringify(exportData);
    const blob = new Blob([exportText], { type: 'application/json' });
    const fileName = `${projectName}-${new Date().toISOString()}.json`;
    const url = window.URL.createObjectURL(blob);
    try {
      exportLinkRef.current.href = url;
      exportLinkRef.current.download = fileName;
      exportLinkRef.current.click();
    } finally {
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Card loading={fetching}>
      {contextHolder}
      <Flex align="center" vertical>
        <Form
          className="project-settings-form"
          initialValues={projectData}
          onFinish={(newParams: Record<string, any>) => {
            setProjectData(newParams as ProjectData);
            setChanged(false);
            setSaving(true);
          }}
          onChange={() => setChanged(true)}
        >
          <Form.Item
            key={'param-name'}
            name="name"
            label="Name"
            rules={requiredRules}
            required={false}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={'param-gpt-key'}
            name="gpt_key"
            label="GPT key"
            required={false}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          {/* <Form.Item
            key={'param-update-gpt-key-limit'}
            name="update_gpt_key_limit"
            label="Update GPT key limit"
            required={false}
            valuePropName="checked"
          >
            <Checkbox
              checked={updateGptLimit}
              onChange={(e) => {
                setUpdateGptLimit(e.target.checked);
              }}
            />
          </Form.Item>
          <Form.Item
            key={'param-gpt-key-limit'}
            name="gpt_key_limit"
            label="GPT key limit"
            required={false}
          >
            <InputNumber disabled={!updateGptLimit} />
          </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!changed}>
              Save
            </Button>
          </Form.Item>
        </Form>
        {user?.is_admin && (
          <>
            <Divider />
            <Button icon={<DownloadOutlined />} onClick={onExport}>
              Export
            </Button>
            <a style={{ display: 'none' }} ref={exportLinkRef} />
          </>
        )}
      </Flex>
    </Card>
  );
}
