import { MessageInstance } from 'antd/es/message/interface';
import { CanceledError } from 'axios';
import { useEffect, useRef, useState } from 'react';

export function useFirstRender() {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

export function useHTTP<DataType>(
  httpFn: (abortController?: AbortController) => Promise<DataType | null>,
  initialRequest: boolean = true,
  errMsg: string | ((err: Error) => string) = 'Error',
  messageApi?: MessageInstance,
): [DataType | null, boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState(initialRequest);
  const [requested, setRequested] = useState(initialRequest);
  useEffect(() => {
    if (!requested) return;
    const abortController = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const fetchedData = await httpFn(abortController);
        setData(fetchedData);
      } catch (error: any) {
        if (error instanceof CanceledError) return;
        const msg = typeof errMsg === 'string' ? errMsg : errMsg(error);
        if (messageApi) {
          messageApi.error(msg);
        }
        console.log(msg, error);
      }
      setLoading(false);
      setRequested(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [requested, httpFn, errMsg, messageApi]);

  return [data, loading, setRequested];
}
