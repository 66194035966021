import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { NodeDataType } from './types';

export type IONodeType = Node<{ isOutput: boolean } & NodeDataType, 'IO'>;

export default function IONode({
  data: { isOutput, isMultiInput, isMultiOutput },
  isConnectable,
}: NodeProps<IONodeType>) {
  return (
    <div className="io-node">
      {isOutput && (
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          className={isMultiInput ? 'multi-handle' : ''}
        />
      )}
      {isOutput ? 'Output' : 'Input'}
      {!isOutput && (
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
          className={isMultiOutput ? 'multi-handle' : ''}
        />
      )}
    </div>
  );
}
