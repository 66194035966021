import { createContext } from 'react';
import { User } from './client';

export type AppContextParams = {
  projectId: string | null;
  setProjectId: (projectId: string | null) => void;
  projectName?: string | null;
  setProjectName: (projectName: string | null) => void;
  user?: User | null;
  setUser: (user: User | null) => void;
};

export const AppContext = createContext<AppContextParams>({
  projectId: null,
  setProjectId: () => {},
  setProjectName: () => {},
  setUser: () => {},
});
