import { ReactNode } from 'react';
import { FrownOutlined } from '@ant-design/icons';
import { Empty, Flex, Typography } from 'antd';

export default function ErrorPlaceholder({
  children,
  description = 'Error',
}: {
  children?: ReactNode;
  description?: string;
}) {
  return (
    <Flex align="center" justify="center" className="lpe-placeholder">
      {children}
      <Empty
        description={<Typography.Title level={3}>{description}</Typography.Title>}
        image={<FrownOutlined style={{ fontSize: '5em' }} />}
      />
    </Flex>
  );
}
