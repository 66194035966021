import IONode from './IONode';
import IfNode from './IfNode';
import OperatorNode from './OperatorNode';
import SubgraphNode from './SubgraphNode';

import './style.css';

const nodeTypes = {
  IO: IONode,
  Operator: OperatorNode,
  Subgraph: SubgraphNode,
  If: IfNode,
};

export default nodeTypes;
