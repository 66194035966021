import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Table,
  TableProps,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';

import { createDataset, deleteDataset, listDatasets } from '../client';
import { AxiosError, CanceledError } from 'axios';
import { AppContext } from '../context';
import { useFirstRender, useHTTP } from '../utils';

type DatasetListData = {
  id: number;
  key: string;
  name: string;
  n_entries: number;
};

type FormDataType = {
  name: string;
  csv_file: { file: File };
};

const requiredRules = [{ required: true, message: 'Required field!' }];

function AddModal({
  open,
  onClose,
  onSuccess,
  projectId,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  projectId: string;
}) {
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState<FormDataType | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [changed, setChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!processing) return;
    if (!formData) {
      setProcessing(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        await createDataset(
          projectId,
          formData.name,
          formData.csv_file.file,
          abortController
        );
        setProcessing(false);
        setFileList([]);
        onClose();
        onSuccess();
        message.success('Added');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        if (error instanceof AxiosError && error.status === 409) {
          const errDetail: string | undefined = error.response?.data.detail;
          if (errDetail) {
            messageApi.error(errDetail);
            setChanged(true);
            onClose();
            return;
          }
        }
        messageApi.error('Error adding dataset');
        console.log('Error adding dataset:', error);
        setChanged(true);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, formData, projectId, messageApi]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      closeIcon={null}
      okButtonProps={{
        loading: processing,
        disabled: !changed,
        form: 'add-project-form',
        htmlType: 'submit',
      }}
    >
      {contextHolder}
      <Typography.Title level={5}>Add</Typography.Title>
      <Form
        name="add-project-form"
        className="project-create-form"
        onFinish={(newParams: Record<string, any>) => {
          setFormData(newParams as FormDataType);
          setChanged(false);
          setProcessing(true);
        }}
        onChange={() => setChanged(true)}
      >
        <Form.Item
          key={'param-name'}
          name="name"
          label="Name"
          rules={requiredRules}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={'param-csv'}
          name="csv_file"
          label="CSV"
          rules={requiredRules}
          required
        >
          <Upload.Dragger
            className="import-dropzone"
            multiple={false}
            customRequest={() => {}}
            beforeUpload={async (file) => {
              setFileList([file]);
              return false;
            }}
            maxCount={1}
            onChange={() => setChanged(true)}
            fileList={fileList}
          >
            <Typography.Text>Drop CSV file here</Typography.Text>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function DeleteModal({
  open,
  onClose,
  onSuccess,
  projectId,
  dataset,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  projectId: string;
  dataset: DatasetListData;
}) {
  const [processing, setProcessing] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (!processing) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await deleteDataset(projectId, dataset.id, abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        message.success('Deleted');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error deleting dataset');
        console.log('Error deleting dataset:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, projectId, messageApi, dataset.id]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing, danger: true }}
      okText="Delete"
    >
      {contextHolder}
      <Typography.Title level={5}>Delete {dataset.name}?</Typography.Title>
    </Modal>
  );
}

export default function DatasetsList() {
  const [opennedModal, setOpennedModal] = useState<'add' | 'delete' | null>(
    null
  );
  const [currentDataset, setCurrentDataset] = useState<DatasetListData | null>(
    null
  );

  const [messageApi, contextHolder] = message.useMessage();

  const { projectId } = useContext(AppContext);

  const fetchDatasets = useCallback(
    async (abortController?: AbortController) => {
      if (projectId === null) return null;
      const datasets = await listDatasets(projectId, abortController);
      const tableData = datasets.map(
        ({ id, name, n_entries }) =>
          ({ id, name, n_entries, key: id.toString(10) } as DatasetListData)
      );
      tableData.sort((a, b) => a.name.localeCompare(b.name));
      return tableData;
    },
    [projectId]
  );

  const [data, loading, setFetchRequested] = useHTTP(
    fetchDatasets,
    true,
    'Error fetching datasets list',
    messageApi
  );

  const onModified = useCallback(() => {
    setFetchRequested(true);
  }, [setFetchRequested]);

  const closeModal = useCallback(() => {
    setOpennedModal(null);
  }, []);

  const columns: TableProps<DatasetListData>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Rows',
      dataIndex: 'n_entries',
      key: 'n_entries',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (value, record) => (
        <>
          <Button
            className="project-list-delete-btn"
            shape="circle"
            type="text"
            icon={<DeleteOutlined />}
            onClick={(event) => {
              setCurrentDataset(record);
              setOpennedModal('delete');
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex
        vertical
        align="flex-start"
        gap="middle"
        className="project-list-container"
      >
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setCurrentDataset(null);
            setOpennedModal('add');
          }}
        >
          Add
        </Button>
        <Table
          className="project-list"
          columns={columns}
          dataSource={data || []}
          showHeader={false}
          loading={loading}
          pagination={false}
        />
        {projectId !== null && (
          <AddModal
            open={opennedModal === 'add'}
            onClose={closeModal}
            onSuccess={onModified}
            projectId={projectId}
          />
        )}
        {projectId !== null && currentDataset && (
          <DeleteModal
            open={opennedModal === 'delete'}
            onClose={closeModal}
            onSuccess={onModified}
            projectId={projectId}
            dataset={currentDataset}
          />
        )}
      </Flex>
    </>
  );
}
