import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  addEndpoint,
  EndpointData,
  deleteEndpoint,
  GraphListEntry,
  listEndpoints,
  // updateEndpoint,
  listGraphs,
  EndpointListEntry,
  updateEndpoint,
} from '../client';
import { AxiosError, CanceledError } from 'axios';
import { AppContext } from '../context';
import { Rule } from 'antd/es/form';

type EndpointListData = {
  key: string;
} & EndpointListEntry;

const requiredRules: Rule[] = [{ required: true, message: 'Required field!' }];
const checkNameRules: Rule[] = [
  { required: true, message: 'Invalid name!', pattern: /^[a-z0-9-_]+$/ },
];

function AddUpdateModal({
  open,
  onClose,
  onSuccess,
  graphs,
  endpoint,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  graphs: GraphListEntry[];
  endpoint?: EndpointListData | null;
}) {
  const [processing, setProcessing] = useState(false);
  const [endpointData, setEndpointData] = useState<EndpointData | undefined>();
  const [changed, setChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { projectId } = useContext(AppContext);
  const initialValues = useMemo<EndpointData | undefined>(() => {
    if (!endpoint) return undefined;
    const { name, graph_id } = endpoint;
    return { name, graph_id };
  }, [endpoint]);

  useEffect(() => {
    if (!processing || projectId === null) return;
    if (!endpointData) {
      setProcessing(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        console.log('endpointData', endpointData);
        if (endpoint) {
          await updateEndpoint(
            projectId,
            endpoint.name,
            endpointData,
            abortController
          );
        } else {
          await addEndpoint(projectId, endpointData, abortController);
        }
        setProcessing(false);
        onClose();
        onSuccess();
        setEndpointData(undefined);
        if (endpoint) {
          message.success('Updated');
        } else {
          message.success('Added');
        }
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        const errMsg = endpoint
          ? 'Error updating endpoint'
          : 'Error adding endpoint';
        messageApi.error(errMsg);
        console.log(errMsg, error);
        setChanged(true);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [
    processing,
    onSuccess,
    onClose,
    endpointData,
    projectId,
    messageApi,
    endpoint,
  ]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      closeIcon={null}
      destroyOnClose
      okButtonProps={{
        loading: processing,
        disabled: !changed,
        form: 'endpoint-add-form',
        htmlType: 'submit',
      }}
    >
      {contextHolder}
      <Typography.Title level={5}>
        {endpoint ? endpoint.name : 'Add'}
      </Typography.Title>
      <Form
        className="endpoint-add-form"
        name="endpoint-add-form"
        initialValues={initialValues}
        onFinish={(newParams: Record<string, any>) => {
          setEndpointData(newParams as EndpointData);
          setProcessing(true);
          setChanged(false);
        }}
        disabled={processing}
        onChange={() => setChanged(true)}
      >
        <Form.Item
          key="param-name"
          name="name"
          label="Name"
          rules={checkNameRules}
          required={false}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="param-graph"
          name="graph_id"
          label="Graph"
          rules={requiredRules}
          required={false}
        >
          <Select
            options={graphs.map(({ id, name }) => ({ value: id, label: name }))}
            onChange={() => setChanged(true)}
          />
        </Form.Item>
        <Form.Item
          key="param-token"
          name="token"
          label="Token"
          rules={endpoint ? undefined : requiredRules}
          required={false}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function DeleteModal({
  open,
  onClose,
  onSuccess,
  endpointId,
  endpointName,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  endpointId: string;
  endpointName: string;
}) {
  const [processing, setProcessing] = useState(false);
  const [messageApi] = message.useMessage();
  const { projectId } = useContext(AppContext);
  useEffect(() => {
    if (!processing || projectId === null) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await deleteEndpoint(projectId, endpointId, abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        message.success('Deleted');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error deleting endpoint');
        console.log('Error deleting endpoint:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, endpointId, projectId, messageApi]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing, danger: true }}
      okText="Delete"
    >
      <Typography.Title level={5}>Delete {endpointName}?</Typography.Title>
    </Modal>
  );
}

export default function EndpointList() {
  const [opennedModal, setOpennedModal] = useState<
    'add' | 'update' | 'delete' | null
  >(null);
  const [currentEndpoint, setCurrentEndpoint] =
    useState<EndpointListData | null>(null);

  const [data, setData] = useState<EndpointListData[]>([]);
  const [graphs, setGraphs] = useState<GraphListEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchRequested, setFetchRequested] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const { projectId } = useContext(AppContext);

  useEffect(() => {
    if (!fetchRequested || projectId === null) return;
    const abortController = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const endpoints = await listEndpoints(projectId, abortController);
        const tableData = endpoints.map(
          ({ name, ...rest }) =>
            ({ key: name, name, ...rest } as EndpointListData)
        );
        tableData.sort((a, b) => a.name.localeCompare(b.name));
        setData(tableData);
        const graphList = await listGraphs(projectId, abortController);
        setGraphs(graphList);
        setLoading(false);
        setFetchRequested(false);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching endpoint list');
        setLoading(false);
        setFetchRequested(false);
        console.log('Error fetching endpoint list:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [fetchRequested, projectId, messageApi]);

  const onModified = useCallback(() => {
    setFetchRequested(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpennedModal(null);
  }, []);

  const columns: TableProps<EndpointListData>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Graph',
      dataIndex: 'graph_name',
      key: 'graph_name',
    },
    {
      render: (value: string, record) => (
        <>
          <Button
            className="graph-list-rename-btn"
            shape="circle"
            type="text"
            icon={<LinkOutlined />}
            onClick={(event) => {
              navigator.clipboard.writeText(
                `https://lpe.nanoeti.com/api/projects/${projectId}/endpoints/${record.name}`
              );
              messageApi.info('Link copied');
            }}
          />
          <Button
            className="graph-list-rename-btn"
            shape="circle"
            type="text"
            icon={<EditOutlined />}
            onClick={(event) => {
              setCurrentEndpoint(record);
              setOpennedModal('update');
            }}
          />
          <Button
            className="graph-list-delete-btn"
            shape="circle"
            type="text"
            icon={<DeleteOutlined />}
            onClick={(event) => {
              setCurrentEndpoint(record);
              setOpennedModal('delete');
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex
        vertical
        align="flex-start"
        gap="middle"
        className="graph-list-container"
      >
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setCurrentEndpoint(null);
            setOpennedModal('add');
          }}
        >
          Add
        </Button>
        <Table
          className="graph-list"
          columns={columns}
          dataSource={data}
          showHeader
          loading={loading}
          pagination={false}
        />
        <AddUpdateModal
          open={opennedModal === 'add' || opennedModal === 'update'}
          onClose={closeModal}
          onSuccess={onModified}
          graphs={graphs}
          endpoint={opennedModal === 'update' ? currentEndpoint : undefined}
        />
        <DeleteModal
          open={opennedModal === 'delete'}
          onClose={closeModal}
          onSuccess={onModified}
          endpointId={currentEndpoint?.key || ''}
          endpointName={currentEndpoint?.name || ''}
        />
      </Flex>
    </>
  );
}
