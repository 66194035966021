import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Input,
  message,
  Modal,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { addGraph, deleteGraph, listGraphs, updateGraph } from '../client';
import { AxiosError, CanceledError } from 'axios';
import { AppContext } from '../context';

type GraphListData = {
  key: string;
  name: string;
};

function AddModal({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const [processing, setProcessing] = useState(false);
  const [graphName, setGraphName] = useState('');
  const [messageApi] = message.useMessage();
  const { projectId } = useContext(AppContext);

  useEffect(() => {
    if (!processing || projectId === null) return;
    if (!graphName) {
      setProcessing(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        await addGraph(projectId, graphName, abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        setGraphName('');
        message.success('Added');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error adding graph');
        console.log('Error adding graph:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, graphName, projectId, messageApi]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing }}
    >
      <Typography.Title level={5}>Add</Typography.Title>
      <Input
        value={graphName}
        disabled={processing}
        onChange={(event) => {
          setGraphName(event.target.value);
        }}
      />
    </Modal>
  );
}

function RenameModal({
  open,
  onClose,
  onSuccess,
  graphId,
  initialName,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  graphId: string;
  initialName: string;
}) {
  const [processing, setProcessing] = useState(false);
  const [graphName, setGraphName] = useState(initialName);
  const [messageApi] = message.useMessage();
  const { projectId } = useContext(AppContext);

  useEffect(() => {
    setGraphName(initialName);
  }, [initialName]);

  useEffect(() => {
    if (!processing) return;
    if (!graphName || projectId === null) {
      setProcessing(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        await updateGraph(
          projectId,
          graphId,
          { id: graphId, name: graphName },
          abortController
        );
        setProcessing(false);
        onClose();
        onSuccess();
        message.success('Renamed');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error renaming graph');
        console.log('Error renaming graph:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [
    processing,
    onSuccess,
    onClose,
    graphId,
    graphName,
    projectId,
    messageApi,
  ]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing }}
    >
      <Typography.Title level={5}>Rename</Typography.Title>
      <Input
        value={graphName}
        disabled={processing}
        onChange={(event) => {
          setGraphName(event.target.value);
        }}
      />
    </Modal>
  );
}

function DeleteModal({
  open,
  onClose,
  onSuccess,
  graphId,
  graphName,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  graphId: string;
  graphName: string;
}) {
  const [processing, setProcessing] = useState(false);
  const [messageApi] = message.useMessage();
  const { projectId } = useContext(AppContext);
  useEffect(() => {
    if (!processing || projectId === null) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await deleteGraph(projectId, graphId, abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        message.success('Deleted');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        messageApi.error('Error deleting graph');
        console.log('Error deleting graph:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, graphId, projectId, messageApi]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing, danger: true }}
      okText="Delete"
    >
      <Typography.Title level={5}>Delete {graphName}?</Typography.Title>
    </Modal>
  );
}

export default function GraphsList() {
  const [opennedModal, setOpennedModal] = useState<
    'add' | 'rename' | 'delete' | null
  >(null);
  const [currentGraph, setCurrentGraph] = useState<GraphListData | null>(null);

  const [data, setData] = useState<GraphListData[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchRequested, setFetchRequested] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const { projectId } = useContext(AppContext);

  useEffect(() => {
    if (!fetchRequested || projectId === null) return;
    const abortController = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const graphs = await listGraphs(projectId, abortController);
        const tableData = graphs.map(
          ({ id, name }) => ({ name, key: id } as GraphListData)
        );
        tableData.sort((a, b) => a.name.localeCompare(b.name));
        setData(tableData);
        setLoading(false);
        setFetchRequested(false);
      } catch (error) {
        if (error instanceof CanceledError) return;
        messageApi.error('Error fetching graph list');
        setLoading(false);
        setFetchRequested(false);
        console.log('Error fetching graph list:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [fetchRequested, projectId, messageApi]);

  useEffect(() => {
    setFetchRequested(true);
  }, [projectId]);

  const onModified = useCallback(() => {
    setFetchRequested(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpennedModal(null);
  }, []);

  const columns: TableProps<GraphListData>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record) => (
        <>
          <Link className="graph-list-edit-btn" to={`/graphs/${record.key}`}>
            {value}
          </Link>
          <Button
            className="graph-list-rename-btn"
            shape="circle"
            type="text"
            icon={<EditOutlined />}
            onClick={(event) => {
              setCurrentGraph(record);
              setOpennedModal('rename');
            }}
          />
          <Button
            className="graph-list-delete-btn"
            shape="circle"
            type="text"
            icon={<DeleteOutlined />}
            onClick={(event) => {
              setCurrentGraph(record);
              setOpennedModal('delete');
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex
        vertical
        align="flex-start"
        gap="middle"
        className="graph-list-container"
      >
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setCurrentGraph(null);
            setOpennedModal('add');
          }}
        >
          Add
        </Button>
        <Table
          className="graph-list"
          columns={columns}
          dataSource={data}
          showHeader={false}
          loading={loading}
          pagination={false}
        />
        <AddModal
          open={opennedModal === 'add'}
          onClose={closeModal}
          onSuccess={onModified}
        />
        <RenameModal
          open={opennedModal === 'rename'}
          onClose={closeModal}
          onSuccess={onModified}
          graphId={currentGraph?.key || ''}
          initialName={currentGraph?.name || ''}
        />
        <DeleteModal
          open={opennedModal === 'delete'}
          onClose={closeModal}
          onSuccess={onModified}
          graphId={currentGraph?.key || ''}
          graphName={currentGraph?.name || ''}
        />
      </Flex>
    </>
  );
}
