import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import { IOInfo, NodeDataType } from './types';

type ParamDisplayType = 'text' | 'textarea';

export type OperatorParamType = {
  name: string;
  value?: string;
  label?: string;
  required?: boolean;
  type?: ParamDisplayType;
};

export type OperatorNodeDataType = {
  operatorName: string;
  label?: string;
  inputs?: IOInfo[];
  outputs?: IOInfo[];
  params?: OperatorParamType[];
  onParamsChange?: (nodeId: string, params: OperatorParamType[]) => void;
  bgColor?: string;
} & NodeDataType;

export type OperatorNodeType = Node<OperatorNodeDataType, 'Operator'>;

export default function OperatorNode({
  data,
  isConnectable,
  id: nodeId,
}: NodeProps<OperatorNodeType>) {
  const {
    operatorName,
    inputs,
    outputs,
    label,
    params,
    onParamsChange,
    bgColor,
  } = data;
  const [changed, setChanged] = useState(false);
  const formValues = Object.fromEntries(
    params?.map((param) => [param.name, param.value]) || []
  );

  const form = params && (
    <Form
      className="operator-node-params-form"
      initialValues={formValues}
      onFinish={(newParams: Record<string, any>) => {
        if (onParamsChange)
          onParamsChange(
            nodeId,
            params.map((param) => ({ ...param, value: newParams[param.name] }))
          );
        setChanged(false);
      }}
      onChange={() => setChanged(true)}
    >
      {(params || []).map((param, idx) => (
        <Form.Item
          key={`param-${idx}`}
          name={param.name}
          label={param.label}
          required={param.required}
          rules={
            param.required
              ? [{ required: true, message: 'Required parameter!' }]
              : undefined
          }
        >
          {param.type === 'textarea' ? (
            <Input.TextArea className="nodrag" cols={60} />
          ) : (
            <Input className="nodrag" />
          )}
        </Form.Item>
      ))}
      {changed && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      )}
    </Form>
  );

  let inputHandles;
  if (inputs && inputs.length) {
    const space = 100 / inputs.length;
    const halfspace = space / 2;
    inputHandles = inputs.map(({ name, type }, idx) => (
      <Handle
        id={name}
        key={idx}
        style={{ left: `${idx * space + halfspace}%` }}
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        className={type === 'multi' ? 'multi-handle' : ''}
      />
    ));
  }

  let outputHandles;
  if (outputs && outputs.length) {
    const space = 100 / outputs.length;
    const halfspace = space / 2;
    outputHandles = outputs.map(({ name, type }, idx) => (
      <Handle
        id={name}
        key={idx}
        style={{ left: `${idx * space + halfspace}%` }}
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        className={type === 'multi' ? 'multi-handle' : ''}
      />
    ));
  }

  const style = bgColor ? { backgroundColor: bgColor } : undefined;

  return (
    <div className="operator-node" style={style}>
      {inputHandles}
      {label || operatorName}
      {form}
      {outputHandles}
    </div>
  );
}
