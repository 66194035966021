import { Menu } from 'antd';
import { NodeDescription } from '../client';

export type ToolType = 'select' | 'if' | 'subgraph' | 'foreach' | string;

type ToolsMenuProps = {
  key: ToolType;
  label: string;
};

const baseTools: ToolsMenuProps[] = [
  {
    key: 'select',
    label: 'Select',
  },
  {
    key: 'if',
    label: 'If',
  },
  {
    key: 'subgraph',
    label: 'Subgraph',
  },
  {
    key: 'foreach',
    label: 'For each',
  },
];

export type ToolsPanelProps = {
  selctedTool: string;
  nodesDescriptions: NodeDescription[];
  onSelectTool?: (toolType: ToolType) => void;
};

export default function ToolsPanel({
  selctedTool,
  nodesDescriptions,
  onSelectTool,
}: ToolsPanelProps) {
  const onSelect = (info: any) => {
    if (onSelectTool) onSelectTool(info.selectedKeys[0]);
  };
  const tools = [
    ...baseTools,
    ...nodesDescriptions.map(
      (nd) => ({ key: nd.name, label: nd.label } as ToolsMenuProps)
    ),
  ];
  return (
    <Menu
      className="graph-tools"
      selectedKeys={[selctedTool]}
      mode="inline"
      items={tools}
      onSelect={onSelect}
      multiple={false}
    />
  );
}
