import { message, Modal, Typography } from 'antd';
import { CanceledError } from 'axios';
import { useEffect, useState } from 'react';

export default function ActionModal({
  open,
  onClose,
  onSuccess,
  action,
  body,
  successMsg,
  errMsg,
  okText,
  danger = true,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  action: (abortController?: AbortController) => Promise<void>;
  body: string;
  successMsg: string;
  errMsg: string | ((err: Error) => string);
  okText: string;
  danger?: boolean;
}) {
  const [processing, setProcessing] = useState(false);
  const [messageApi] = message.useMessage();
  useEffect(() => {
    if (!processing) return;
    const abortController = new AbortController();
    (async () => {
      try {
        await action(abortController);
        setProcessing(false);
        onClose();
        onSuccess();
        message.success(successMsg);
      } catch (error: any) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        const msg = typeof errMsg === 'string' ? errMsg : errMsg(error);
        messageApi.error(msg);
        console.log(msg, error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, messageApi, action, errMsg, successMsg]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        setProcessing(true);
      }}
      closeIcon={null}
      okButtonProps={{ loading: processing, danger }}
      okText={okText}
    >
      <Typography.Title level={5}>{body}</Typography.Title>
    </Modal>
  );
}
