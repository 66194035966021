import {
  Button,
  Flex,
  Form,
  message,
  Modal,
  Progress,
  Select,
  Spin,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { AxiosError, CanceledError } from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from '../context';
import {
  cancelTask,
  createTask,
  DatasetListEntry,
  deleteTask,
  getTaskStats,
  GraphListEntry,
  listDatasets,
  listGraphs,
  listTasks,
  ProcessingTaskListEntry,
  rerunFailedTask,
} from '../client';
import { useHTTP } from '../utils';
import {
  DeleteOutlined,
  ExportOutlined,
  ReloadOutlined,
  StopOutlined,
} from '@ant-design/icons';
import ActionModal from './ActionModal';
import { MessageInstance } from 'antd/es/message/interface';
import { Link } from 'react-router-dom';

type TaskListData = {
  key: string;
} & ProcessingTaskListEntry;

type CreateTaskData = {
  graph_id: string;
  datasets: number[];
};

const requiredRules = [{ required: true, message: 'Required field!' }];

function AddModal({
  open,
  onClose,
  onSuccess,
  projectId,
  loading,
  graphs,
  datasets,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  projectId: string;
  loading: boolean;
  graphs: GraphListEntry[];
  datasets: DatasetListEntry[];
}) {
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState<CreateTaskData | null>(null);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!processing) return;
    if (!formData) {
      setProcessing(false);
      return;
    }
    const abortController = new AbortController();
    (async () => {
      try {
        await createTask(
          projectId,
          formData.graph_id,
          formData.datasets,
          abortController
        );
        setProcessing(false);
        form.resetFields();
        onClose();
        onSuccess();
        message.success('Created');
      } catch (error) {
        if (error instanceof CanceledError) return;
        setProcessing(false);
        if (error instanceof AxiosError && error.status === 409) {
          const errDetail: string | undefined = error.response?.data.detail;
          if (errDetail) {
            messageApi.error(errDetail);
            onClose();
            return;
          }
        }
        messageApi.error('Error creating task');
        console.log('Error creating task:', error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, onSuccess, onClose, formData, projectId, messageApi, form]);

  const graphOptions = useMemo(
    () => graphs.map(({ id, name }) => ({ value: id, label: name })),
    [graphs]
  );

  const datasetsOptions = useMemo(
    () => datasets.map(({ id, name }) => ({ value: id, label: name })),
    [datasets]
  );

  return (
    <Modal
      open={open}
      onCancel={onClose}
      loading={loading}
      closeIcon={null}
      okButtonProps={{
        loading: processing,
        form: 'add-task-form',
        htmlType: 'submit',
      }}
    >
      {contextHolder}
      <Typography.Title level={5}>Add</Typography.Title>
      <Form
        name="add-task-form"
        className="project-create-form"
        onFinish={(newParams: Record<string, any>) => {
          setFormData(newParams as CreateTaskData);
          setProcessing(true);
        }}
        form={form}
      >
        <Form.Item
          key={'param-graph-id'}
          name="graph_id"
          label="Graph"
          rules={requiredRules}
          required={false}
        >
          <Select
            showSearch
            placeholder="Select graph"
            optionFilterProp="label"
            // onChange={onChange}
            // onSearch={onSearch}
            options={graphOptions}
          />
        </Form.Item>
        <Form.Item
          key={'param-datasets'}
          name="datasets"
          label="Datasets"
          rules={requiredRules}
          required={false}
        >
          <Select
            mode="multiple"
            showSearch
            placeholder="Select datasets"
            optionFilterProp="label"
            // onChange={onChange}
            // onSearch={onSearch}
            options={datasetsOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function RerunBtn({
  projectId,
  taskId,
  disabled,
  messageApi,
}: {
  projectId: string;
  taskId: number;
  disabled?: boolean;
  messageApi: MessageInstance;
}) {
  const rerun = useCallback(
    async (abortController?: AbortController) => {
      return await rerunFailedTask(projectId, taskId, abortController);
    },
    [projectId, taskId]
  );

  const [n_reruned, loading, setRerunRequested] = useHTTP<number>(
    rerun,
    false,
    'Error reruning task',
    messageApi
  );

  return (
    <Button
      className="tasks-list-action-btn"
      disabled={disabled}
      loading={loading}
      icon={<ReloadOutlined />}
      onClick={() => {
        setRerunRequested(true);
      }}
    >
      Rerun failed
    </Button>
  );
}

function TaskProgress({
  task,
  projectId,
  messageApi,
}: {
  task: TaskListData;
  projectId: string;
  messageApi: MessageInstance;
}) {
  const getStats = useCallback(
    async (abortController?: AbortController) => {
      return await getTaskStats(projectId, task.id, abortController);
    },
    [projectId, task.id]
  );

  const [stats, loading, setFetchRequested] = useHTTP(
    getStats,
    false,
    'Error getting task progress',
    messageApi
  );

  useEffect(() => {
    if (task.status === 'new' || task.status === 'pending') {
      setFetchRequested(true);
      const interval = window.setInterval(() => {
        setFetchRequested(true);
      }, 10_000);
      return () => {
        window.clearInterval(interval);
      };
    }
  }, [task.status, setFetchRequested]);

  if (task.status === 'success') {
    return <Progress percent={100} />;
  }
  if (task.status === 'cancelled') {
    return <Progress percent={100} status="exception" strokeColor="#fadb14" />;
  }
  if (task.status === 'failed') {
    return <Progress percent={100} status="exception" />;
  }

  if (!stats) return <Spin />;

  const success_pct = (stats.n_success / stats.n_total) * 100;
  const progress_pct =
    ((stats.n_success + stats.n_failed) / stats.n_total) * 100;

  return (
    <Progress
      percent={progress_pct}
      strokeColor="red"
      success={{ percent: success_pct }}
      format={() =>
        `${stats.n_total - stats.n_new - stats.n_pending}/${stats.n_total}`
      }
    />
  );
}

export default function TasksList() {
  const [opennedModal, setOpennedModal] = useState<
    'add' | 'cancel' | 'delete' | null
  >(null);
  const [currentTask, setCurrentTask] = useState<TaskListData | null>(null);

  const [messageApi, contextHolder] = message.useMessage();
  const { projectId } = useContext(AppContext);

  const fetchTasks = useCallback(
    async (abortController?: AbortController) => {
      if (projectId === null) return null;
      const tasks = await listTasks(projectId, abortController);
      console.log(tasks);
      return tasks.map(
        ({ id, ...rest }) =>
          ({ key: id.toString(10), id, ...rest } as TaskListData)
      );
    },
    [projectId]
  );

  const fetchDatasets = useCallback(
    async (abortController?: AbortController) => {
      if (projectId === null) return null;
      return await listDatasets(projectId, abortController);
    },
    [projectId]
  );

  const fetchGraphs = useCallback(
    async (abortController?: AbortController) => {
      if (projectId === null) return null;
      return await listGraphs(projectId, abortController);
    },
    [projectId]
  );

  const [tasks, loadingT, setFetchRequestedT] = useHTTP(
    fetchTasks,
    true,
    'Error fetching tasks',
    messageApi
  );

  const [datasets, loadingD, setFetchRequestedD] = useHTTP(
    fetchDatasets,
    true,
    'Error fetching datasets',
    messageApi
  );

  const [graphs, loadingG, setFetchRequestedG] = useHTTP(
    fetchGraphs,
    true,
    'Error fetching graphs',
    messageApi
  );

  useEffect(() => {
    setFetchRequestedT(true);
    setFetchRequestedD(true);
    setFetchRequestedG(true);
  }, [projectId, setFetchRequestedT, setFetchRequestedD, setFetchRequestedG]);

  const addModalLoading = loadingT || loadingD || loadingG;

  const onModified = useCallback(() => {
    setFetchRequestedT(true);
  }, [setFetchRequestedT]);

  const closeModal = useCallback(() => {
    setOpennedModal(null);
  }, []);

  const deleteTaskAction = useCallback(
    async (abortController?: AbortController) => {
      if (projectId === null || !currentTask?.id) return;
      await deleteTask(projectId, currentTask.id, abortController);
    },
    [projectId, currentTask]
  );

  const cancelTaskAction = useCallback(
    async (abortController?: AbortController) => {
      if (projectId === null || !currentTask?.id) return;
      await cancelTask(projectId, currentTask.id, abortController);
    },
    [projectId, currentTask]
  );

  const columns: TableProps<TaskListData>['columns'] = [
    {
      title: 'Graph',
      dataIndex: 'graph_name',
      key: 'graph_name',
      render: (graphName, task) => (
        <Link to={`/graphs/${task.graph_id}`}>{graphName || 'unnamed'}</Link>
      ),
    },
    {
      title: 'Datasets',
      dataIndex: 'datasets',
      key: 'datasets',
      render: (datasets: Record<number, string>) =>
        Object.entries(datasets).map(([dsId, dsName]) => (
          <p key={dsId}>{dsName}</p>
        )),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Progress',
      key: 'progress',
      render: (value, record) =>
        projectId !== null && (
          <TaskProgress
            projectId={projectId}
            task={record}
            messageApi={messageApi}
          />
        ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (value, record) => {
        const canBeCancelled =
          record.status === 'new' || record.status === 'pending';
        return (
          <div className="tasks-list-actions">
            <Button
              className="tasks-list-action-btn"
              icon={<ExportOutlined />}
              href={`api/projects/${projectId}/tasks/${record.id}/export`}
              download={`${record.id}.csv`}
            >
              Results
            </Button>
            {projectId !== null && (
              <RerunBtn
                projectId={projectId}
                taskId={record.id}
                disabled={record.status !== 'failed'}
                messageApi={messageApi}
              />
            )}
            <Button
              className="tasks-list-action-btn"
              disabled={!canBeCancelled}
              // style={{borderColor: 'yellow', color: 'yellow'}}
              icon={<StopOutlined />}
              onClick={(event) => {
                setCurrentTask(record);
                setOpennedModal('cancel');
              }}
            >
              Cancel
            </Button>
            <Button
              className="tasks-list-action-btn"
              danger
              icon={<DeleteOutlined />}
              onClick={(event) => {
                setCurrentTask(record);
                setOpennedModal('delete');
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex
        vertical
        align="flex-start"
        gap="middle"
        className="project-list-container"
      >
        <div className="project-list-controls">
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setOpennedModal('add');
            }}
          >
            Create
          </Button>
          <Button
            // type="primary"
            size="large"
            icon={<ReloadOutlined />}
            onClick={() => {
              setFetchRequestedT(true);
              setFetchRequestedD(true);
              setFetchRequestedG(true);
            }}
          />
        </div>
        <Table
          className="project-list"
          columns={columns}
          dataSource={tasks || []}
          showHeader
          loading={loadingT}
          pagination={false}
        />
        {projectId !== null && (
          <AddModal
            open={opennedModal === 'add'}
            onClose={closeModal}
            onSuccess={onModified}
            projectId={projectId}
            loading={addModalLoading}
            graphs={graphs || []}
            datasets={datasets || []}
          />
        )}
        {projectId !== null && currentTask && (
          <>
            <ActionModal
              open={opennedModal === 'delete'}
              onClose={closeModal}
              onSuccess={onModified}
              action={deleteTaskAction}
              body={`Delete ${currentTask.id}?`}
              okText="Delete"
              errMsg="Error deleting task"
              successMsg="Deleted"
            />
            <ActionModal
              open={opennedModal === 'cancel'}
              onClose={closeModal}
              onSuccess={onModified}
              action={cancelTaskAction}
              body={`Cancel ${currentTask.id}?`}
              okText="Cancel"
              errMsg="Error cancelling task"
              successMsg="Cancelled"
            />
          </>
        )}
      </Flex>
    </>
  );
}
